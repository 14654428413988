import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'landing',
    component: () =>
      import( /* webpackChunkName:"landing"*/ './../views/Landing.vue'),
    /* meta: {
      redirectToSearchIfLoggedIn: true
    } */
  },
  {
    path: '/register/',
    name: 'register',
    component: () =>
      import( /* webpackChunkName:"register"*/ './../views/Register.vue'),
  },
  {
    path: '*',
    component: () => import( /* webpackChunkName:"404"*/ './../views/404.vue'),
    name: '404',
  },
  {
    path: '/expired',
    component: () =>
      import( /* webpackChunkName:"expired"*/ './../views/ExpiredAd.vue'),
    name: 'expired',
  },
  {
    path: "/expired-token",
    component: () => import( /* webpackChunkName:"expired-token"*/ "./../views/ExpiredToken.vue"),
    name: "expired-token",
  },
  {
    path: '/generation-de-leads/',
    component: () => import( /* webpackChunkName:"generation-de-leads"*/ './../views/Naas.vue'),
    name: 'naas',
  },
  {
    path: '/thanks',
    component: () =>
      import(
        /* webpackChunkName:"thanks-signup"*/
        './../views/ThanksSignup.vue'
      ),
    name: 'thanks-signup',
  },
  {
    path: '/thanks-retarget',
    component: () =>
      import(
        /* webpackChunkName:"thanks-retarget"*/
        './../views/ThanksRetarget.vue'
      ),
    name: 'thanks-retarget',
  },
  {
    path: '/reset-success',
    component: () =>
      import(
        /* webpackChunkName:"reset-success"*/
        './../views/ThanksReset.vue'
      ),
    name: 'reset-success',
  },
  {
    path: '/pricing/',
    component: () =>
      import( /* webpackChunkName:"pricing"*/ './../views/Pricing.vue'),
    name: 'pricing',
  },
  {
    path: '/pricing/naas/:token',
    component: () =>
      import(
        /* webpackChunkName:"custom-pricing-naas"*/
        './../views/CustomPricingNaas.vue'
      ),
    name: 'custom-pricing-naas',
    beforeEnter: (to, from, next) => {
      if (
        to.path.includes('YYYYYYYYYYYYYYYYYYYYYYY')) {
        next()
      } else {
        next({
          name: 'landing'
        })
      }
    },
  },
  {
    path: '/resume/checkout/:uuid',
    component: () =>
      import(
        /* webpackChunkName:"resume-checkout"*/
        './../views/ResumeCheckout.vue'
      ),
    name: 'resume-checkout',
  },
  {
    path: '/pricing/whitelabel/:token',
    component: () =>
      import(
        /* webpackChunkName:"custom-pricing-whitelabel"*/
        './../views/CustomPricingWhiteLabel.vue'
      ),
    name: 'custom-pricing-whitelabel',
    beforeEnter: (to, from, next) => {
      if (
        to.path.includes('YYYYYYYYYYYYYYYYYYYYYYY')
      ) {
        next()
      } else {
        next({
          name: 'landing'
        })
      }
    },
  },
  {
    path: '/confirmation/:confirmationToken',
    component: () =>
      import(
        /* webpackChunkName:"email-confirmed"*/
        './../views/EmailConfirmed.vue'
      ),
    name: 'email-confirmed',
  },
  {
    path: '/confirmation-email-sender',
    component: () =>
      import(
        /* webpackChunkName:"email-sender-confirmed"*/
        './../views/EmailConfirmed.vue'
      ),
    name: 'email-sender-confirmed',
  },
  {
    path: '/display/:token',
    component: () =>
      import( /* webpackChunkName:"display"*/ './../views/AdDisplay.vue'),
    name: 'display',
  },
  {
    path: '/login/',
    component: () =>
      import( /* webpackChunkName:"login"*/ './../views/Login.vue'),
    name: 'login',
    /*  meta: {
      redirectToSearchIfLoggedIn: true
    } */
  },
  {
    path: '/forgot/',
    component: () =>
      import(
        /* webpackChunkName:"forgot-password-request"*/
        './../views/ForgotPasswordRequest.vue'
      ),
    name: 'forgot',
  },
  {
    path: '/reset-password/:token',
    component: () =>
      import(
        /* webpackChunkName:"reset-password"*/
        './../views/ResetPassword.vue'
      ),

    name: 'reset-password',
  },
  {
    path: '/terms/',
    component: () =>
      import( /* webpackChunkName:"terms"*/ './../views/Terms.vue'),
    name: 'terms',
  },
  {
    path: '/privacy/',
    component: () =>
      import( /* webpackChunkName:"privacy"*/ './../views/Privacy.vue'),
    name: 'privacy',
  },
  {
    path: '/affiliation/',
    component: () =>
      import( /* webpackChunkName:"affiliation"*/ './../views/Affiliation.vue'),
    name: 'affiliation',
  },
  {
    path: '/search',
    component: () =>
      import( /* webpackChunkName:"search"*/ './../views/SearchContent.vue'),
    name: 'search',
    meta: {
      requiresAuth: true,
    },
    async beforeEnter(to, from, next) {
      await store.dispatch('fetchStripeAndSubscription').then(() => {
        if (!store.getters.isSubscriptionValid) {
          next({
            name: 'settings',
          })
        } else {
          next()
        }
      })
    },
    async beforeLeave(to, from, next) {
      await store.dispatch('fetchStripeAndSubscription').then(() => {
        if (!store.getters.isSubscriptionValid) {
          next({
            name: 'settings',
          })
        } else {
          next()
        }
      })
    },
  },
  {
    path: '/settings',
    component: () =>
      import( /* webpackChunkName:"settings"*/ './../views/Settings.vue'),
    name: 'settings',
    meta: {
      requiresAuth: true,
      requiresActiveSubscription: false,
    },
    async beforeEnter(to, from, next) {
      await store.dispatch('fetchStripeAndSubscription').then(() => {
        next()
      })
    },
  },
  {
    path: '/blog/',
    component: () =>
      import( /* webpackChunkName:"blog-home"*/ './../views/BlogHome.vue'),
    name: 'blog-home',
  },
  {
    path: '/blog/:slug',
    component: () =>
      import( /* webpackChunkName:"blog-post"*/ './../views/BlogPost.vue'),
    name: 'blog-post',
    props: true
  },
  {
    path: '/status/',
    component: () =>
      import( /* webpackChunkName:"status"*/ './../views/Status.vue'),
    name: 'status',
  },
   
  {
    path: '/api/',
    component: () => import( /* webpackChunkName:"api"*/ './../views/API.vue'),
    name: 'api',
  },
  {
    path: '/opportunities/',
    component: () => import( /* webpackChunkName:"opportunities"*/
      './../views/Opportunities.vue'),
    name: 'opportunities',
  },
  {
    path: '/calcul-taxe-fonciere/',
    component: () => import( /* webpackChunkName:"calcul-taxe-finciere"*/
      './../views/PropertyTax.vue'),
    name: 'property-tax',
  },
  {
    path: '/maintenance/',
    component: () => import( /* webpackChunkName:"maintenance"*/
      './../views/Maintenance.vue'),
    name: 'maintenance',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,  
  // routes: [{
  //   path: '*',
  //   component: () => import( /* webpackChunkName:"Maintenance"*/ './../views/Maintenance.vue'),
  //   name: 'maintenance',
  // }, ]
})


router.afterEach((to, from) => {
  store.commit('UPDATE_LOADING', false)
  store.commit('CLOSE_NAV')
})

router.beforeEach((to, from, next) => {

  store.commit('UPDATE_LOADING', true)
  if (to.matched.some((record) => record.meta.redirectToSearchIfLoggedIn)) {
    if (store.getters.isLoggedIn) {
      next({
        path: '/search',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})



export default router
